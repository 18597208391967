import logo from './logo.svg';
import './App.css';

import Badge from 'react-bootstrap/Badge';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button'

import PinehurstPutting from './PinehurstPutting.jpg';

const day1 = {
  title: "Travel Day",
  breakfast: "McDonalds",
  activity: "Travel down from Canada and DC to the whispering southern pines. Check into the quaint Carolina Hotel and perhaps head to the practice facility. Enjoy a beer and overlook No. 2's 18 Green.",
  excitement: "Thursday",
  dinner: "TBD",
  link: "https://www.pinehurst.com/golf/practice-facilities/",
  linkText: "Practice Facility"
}

const day2 = {
  title: "Round 1",
  breakfast: "Enjoy the complementary southern breakfast, courtesy of the Bed & Breakfast Package",
  activity: "Open the trip with a round on No. 5.",
  excitement: "Friday",
  dinner: "Pinehurst Brewing Co.",
  link: "https://www.pinehurst.com/golf/courses/no-5/",
  linkText: "Learn about No. 5"
}

const day3 = {
  title: "Round 2 / Spa day",
  breakfast: "Enjoy the complementary southern breakfast, courtesy of the Bed & Breakfast Package",
  activity: "Depending on how well golf went, enjoy a couples massage at the Pinehurst Spa or play another round.",
  excitement: "Saturday",
  dinner: "The Tavern",
  link: "https://www.pinehurst.com/spa/",
  linkText: "Learn about the Spa"
}

const day4 = {
  title: "Round 3 / Spa day",
  breakfast: "Enjoy the complementary southern breakfast, courtesy of the Bed & Breakfast Package",
  activity: "Tackle another one of Pinehurst's courses, perhaps number 8, where Garrett can redeem himself from the Jr. Ross",
  excitement: "Sunday",
  dinner: "TBD",
  link: "https://www.pinehurst.com/golf/courses/no-8/",
  linkText: "Learn about No. 8"
}

const day5 = {
  title: "Round 4",
  breakfast: "Enjoy the complementary southern breakfast, courtesy of the Bed & Breakfast Package",
  activity: "Enjoy the walk on Pinehurst No. 2",
  excitement: "Monday",
  dinner: "TBD",
  link: "https://www.pinehurst.com/golf/courses/no-2/",
  linkText: "Learn about No. 2"
}

const day6 = {
  title: "Travel Day",
  breakfast: "Enjoy the complementary southern breakfast, courtesy of the Bed & Breakfast Package",
  activity: "Pack your maple syrup and head back to Canada",
  excitement: "Tuesday",
  dinner: "TBD",
  link: "https://www.pinehurst.com/accommodations/",
  linkText: "Book a Late Checkout"
}

const days = [day1,day2,day3,day4,day5,day6]

function App() {
  return (
    <>
    <Container>
      <Row style={{margin: 'auto', minHeight: '5vh'}}></Row>
      <h1 style={{textAlign: 'center'}}>Funhurst 2021</h1>
      <Row style={{margin: 'auto', minHeight: '5vh'}}></Row>
      <Row>
        {days.map(day => (
          <Col xs={12} md={6} lg={4} style={{paddingBottom: '5vh'}}>
            <Card className="" style={{height: '100%'}}>
              <Card.Img variant="top" src={PinehurstPutting} />
              <Card.Body>
                <Card.Title>{day.title}</Card.Title>
                <Card.Text>
                  <p className="lead"><Badge variant="secondary">Breakfast</Badge></p>
                  <p className="lead">{day.breakfast}</p>
                  <p className="lead"><Badge variant="warning">Activity</Badge></p>
                  <p className="lead">{day.activity}</p>
                  <p className="lead"><Badge variant="info">Dinner</Badge></p>
                  <p className="lead">{day.dinner}</p>
                </Card.Text>
                <Button variant="outline-success" href={day.link} target="_blank">{day.linkText}</Button>
              </Card.Body>
              <Card.Footer>
                {day.excitement}
              </Card.Footer>
            </Card>
          </Col>
        ))}
      </Row>
      <Row style={{margin: 'auto', minHeight: '5vh'}}></Row>
      <p className="lead" style={{textAlign: 'center'}}>&copy; Funhurst 2021</p>
      <Row style={{margin: 'auto', minHeight: '5vh'}}></Row>
      </Container>
    </>
    
  );
}

export default App;
